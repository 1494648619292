import React, { useState, useEffect } from "react"
import WebsiteLayout from "../../components/layout/website/website-layout/website-layout"
import SEO from "../../services/seo"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./index.module.scss"
import classNames from "classnames"
import DownloadStaticFileButton from "../../components/common/download-static-file-button/download-static-file-button"
import Button from "../../components/common/button/button"
import ModalWindow from "../../components/common/modal-window/modal-window"
import MembershipForm from "../../components/common/membership-form"
import FamilyMemberShipForm from "../../components/common/membership-form/family-form"
import CorporateMemberShipForm from "../../components/common/membership-form/corporate-form"
import DonationMemberShipForm from "../../components/common/membership-form/donation-form"
import membership_1 from "./membership_01.jpg"
import membership_2 from "./membership_02.jpg"
import { MdRotateRight } from "react-icons/md"
import { ArrowForward, ArrowRight } from "@material-ui/icons"
import Mail from "../../assets/mail.svg"
import Money from "../../assets/money.svg"

const MembershipPage = () => {
  const data = useStaticQuery(graphql`
    query PageMembershipQuery {
      allStrapiMembership {
        nodes {
          title
          formPdf
          benefitsHeading
          benefits {
            desc
          }
          callToJoinHeading
          callToJoinDesc
          feesHeading
          individualPlanName
          individualPlanCost
          familyPlanName
          familyPlanCost
          corporatePlanName
          corporatePlanCost
          donationPlanName
          donationPlanCost
          email
          addressInfo
          addressTitle
          formInstructions
        }
      }
    }
  `)

  const [modalOpen, setModalOpen] = useState(false)
  const [familyModalOpen, setFamilyModalOpen] = useState(false)
  const [corporateModalOpen, setCorporateModalOpen] = useState(false)
  const [donationModalOpen, setDonationModalOpen] = useState(false)
  const [membershipTypes, setMembershipTypes] = useState()

  console.log("Data" + data.allStrapiMembership.nodes[0])


  useEffect(() => {
    let types = []

    let amount = data.allStrapiMembership.nodes[0].individualPlanCost * 1.00
    // let tax = 2.00
    let totalAmount = amount
    let membership = {
      label: data.allStrapiMembership.nodes[0].individualPlanName,
      value: data.allStrapiMembership.nodes[0].individualPlanName,
      type: data.allStrapiMembership.nodes[0].individualPlanName,
      price: amount,
      // tax: tax,
      totalAmount: totalAmount
    }
    types.push(membership)

    amount = data.allStrapiMembership.nodes[0].familyPlanCost * 1.00
    // tax = data.allStrapiMembership.nodes[0].familyPlanCost * 0.05
    totalAmount = amount
    membership = {
      label: data.allStrapiMembership.nodes[0].familyPlanName,
      value: data.allStrapiMembership.nodes[0].familyPlanName,
      type: data.allStrapiMembership.nodes[0].familyPlanName,
      price: data.allStrapiMembership.nodes[0].familyPlanCost,
      price: amount,
      // tax: tax,
      totalAmount: totalAmount
    }
    types.push(membership)

    amount = data.allStrapiMembership.nodes[0].corporatePlanCost * 1.00
    // tax = data.allStrapiMembership.nodes[0].corporatePlanCost * 0.05
    totalAmount = amount
    membership = {
      label: data.allStrapiMembership.nodes[0].corporatePlanName,
      value: data.allStrapiMembership.nodes[0].corporatePlanName,
      type: data.allStrapiMembership.nodes[0].corporatePlanName,
      price: data.allStrapiMembership.nodes[0].corporatePlanCost,
      price: amount,
      // tax: tax,
      totalAmount: totalAmount
    }
    types.push(membership)

    console.log('Check:', membershipTypes)
    setMembershipTypes(types)
  }, [])

  const onMembershipClick = () => {
    setModalOpen(true)
  }

  const getModalWindow = () => {
    const onModalClose = () => {
      setModalOpen(false)
    }
    if (modalOpen) {
      return (
        <ModalWindow title="Individual Membership Sign Up" closeHandler={onModalClose}>
          <MembershipForm membershipTypes={membershipTypes} />
        </ModalWindow>

      )
    } else {
      return ""
    }
  }

  const onFamilyMembershipClick = () => {
    setFamilyModalOpen(true)
  }

  const getFamilyModalWindow = () => {
    const onModalClose = () => {
      setFamilyModalOpen(false)
    }
    if (familyModalOpen) {
      return (

        <ModalWindow title="Family Membership Sign Up" closeHandler={onModalClose}>
          <FamilyMemberShipForm />
        </ModalWindow>

      )
    } else {
      return ""
    }
  }

  const onCorporateMembershipClick = () => {
    setCorporateModalOpen(true)
  }

  const getCorporateModalWindow = () => {
    const onModalClose = () => {
      setCorporateModalOpen(false)
    }
    if (corporateModalOpen) {
      return (

        <ModalWindow title="Corporate Membership Sign Up" closeHandler={onModalClose}>
          <CorporateMemberShipForm />
        </ModalWindow>

      )
    } else {
      return ""
    }
  }

  const onDonationMembershipClick = () => {
    setDonationModalOpen(true)
  }

  const getDonationModalWindow = () => {
    const onModalClose = () => {
      setDonationModalOpen(false)
    }
    if (donationModalOpen) {
      return (

        <ModalWindow title="Donations" closeHandler={onModalClose}>
          <DonationMemberShipForm />
        </ModalWindow>

      )
    } else {
      return ""
    }
  }

  return (
    <WebsiteLayout>
      <SEO title={data.allStrapiMembership.nodes[0].title} />

      {getModalWindow()}
      {getFamilyModalWindow()}
      {getCorporateModalWindow()}
      {getDonationModalWindow()}
      <div className={styles.wrapper}>
        <div className={styles.membership_1}>
          <div className={styles.container}>
            <div className={styles.info}>
              <h1>{data.allStrapiMembership.nodes[0].benefitsHeading}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.allStrapiMembership.nodes[0].benefits
                    .map(b => b.desc)
                    .slice(0, 3)
                    .join("<br /> <br />"),
                }}
              />
            </div>
            <div className={styles.photo}>
              <img src={membership_1} />
            </div>
          </div>
        </div>
        <div className={styles.membership_2}>
          <div className={styles.container}>
            <div className={styles.photo}>
              <img src={membership_2} />
            </div>
            <div className={styles.info}>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.allStrapiMembership.nodes[0].benefits
                    .map(b => b.desc)
                    .slice(3)
                    .join("<br /> <br />"),
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.join}>
          <div className={styles.container}>
            <h1>{data.allStrapiMembership.nodes[0].callToJoinHeading}</h1>
            <p>{data.allStrapiMembership.nodes[0].callToJoinDesc}</p>
            <h4 className={styles.planTitle}>
              {data.allStrapiMembership.nodes[0].feesHeading}
            </h4>
            <ul className={styles.plans}>
              <li>
                <div
                  className={classNames(styles.icon, styles.planIndividual)}
                ></div>
                <h4 className={styles.name}>
                  {data.allStrapiMembership.nodes[0].individualPlanName}
                </h4>
                <div className={styles.price}>
                  $
                  <span>
                    {data.allStrapiMembership.nodes[0].individualPlanCost}
                  </span>
                  / year
                </div>
                <br />
                <br />
                <div style={{ display: "flex" }}>
                  <p onClick={onMembershipClick} className="title" style={{ color: "#6473F3", fontSize: "20px" }}>Get started</p>
                  <span className="arrow" style={{ color: "#6473F3", marginLeft: "5px", marginTop: "4px" }}><ArrowForward /></span>
                </div>
              </li>
              <li>
                <div
                  className={classNames(styles.icon, styles.planFamily)}
                ></div>
                <h4 className={styles.name}>
                  {data.allStrapiMembership.nodes[0].familyPlanName}
                </h4>
                <div className={styles.price}>
                  $
                  <span>
                    {data.allStrapiMembership.nodes[0].familyPlanCost}
                  </span>
                  / year
                </div>
                <br />
                <br />
                <div style={{ display: "flex" }}>
                  <p onClick={onFamilyMembershipClick} className="title" style={{ color: "#6473F3", fontSize: "20px" }}>Get started</p>
                  <span className="arrow" style={{ color: "#6473F3", marginLeft: "5px", marginTop: "4px" }}><ArrowForward /></span>
                </div>
              </li>
              <li>
                <div
                  className={classNames(styles.icon, styles.planCorporate)}
                ></div>
                <h4 className={styles.name}>
                  {data.allStrapiMembership.nodes[0].corporatePlanName}
                </h4>
                <div className={styles.price}>
                  $
                  <span>
                    {data.allStrapiMembership.nodes[0].corporatePlanCost}
                  </span>
                  / year
                </div>
                <br />
                <br />
                <div style={{ display: "flex" }}>
                  <p onClick={onCorporateMembershipClick} className="title" style={{ color: "#6473F3", fontSize: "20px" }}>Get started</p>
                  <span className="arrow" style={{ color: "#6473F3", marginLeft: "5px", marginTop: "4px" }}><ArrowForward /></span>
                </div>
              </li>
              <li>
                <div
                  className={classNames(styles.icon, styles.planDonation)}
                >
                </div>
                <h4 className={styles.name}>
                  {data.allStrapiMembership.nodes[0].donationPlanName}
                </h4>
                <div className={styles.price}>
                Starting at $ 
                  <span>
                    {data.allStrapiMembership.nodes[0].donationPlanCost}
                  </span>
                  / year
                </div>
                <br />
                <br />

                <div style={{ display: "flex" }}>
                  <p onClick={onDonationMembershipClick} className="title" style={{ color: "#6473F3", fontSize: "20px" }}>Donate now</p>
                  <span className="arrow" style={{ color: "#6473F3", marginLeft: "5px", marginTop: "4px" }}><ArrowForward /></span>
                </div>
              </li>
            </ul>


            <div style={{
              fontWeight: "bold", fontSize: "25px"
            }}> Other Payment Options</div>


            <br />
            <br />

            <div style={{ display: "flex" }}>
              <div style={{
                left: "250px",
                width: "370px",
                height: "280px",
                background: "#FFFFFF 0% 0% no-repeat padding-box"
              }}>
                <div style={{ width: "72px", height: "58px", marginTop: "25px", marginLeft: "150px" }}>
                  <img src={Mail} />
                </div>

                <br />
                <br />
                <p style={{
                  font: "normal normal normal 20px/24px Proxima Nova",
                  letterSpacing: "0.4px",
                  color: "#4A4A4A"
                }}> </p>
                <br />
                <p style={{
                  font: "normal normal normal 20px/24px Proxima Nova",
                  letterSpacing: "0.4px",
                  color: "#4A4A4A"
                }}>{data.allStrapiMembership.nodes[0].email}</p>
              </div>

              <div style={{
                marginLeft: "50px",
                width: "370px",
                height: "280px",
                background: "#FFFFFF 0% 0% no-repeat padding-box"
              }}>
                <div style={{ width: "86px", height: "61px", marginTop: "25px", marginLeft: "150px" }}>
                  <img src={Money} />
                </div>

                <br />
                <br />
                <p style={{
                  font: "normal normal normal 20px/24px Proxima Nova",
                  letterSpacing: "0.4px",
                  color: "#4A4A4A"
                }}>
                  <b>{data.allStrapiMembership.nodes[0].formInstructions}</b>
                </p>
                <br />
                <p style={{
                  font: "normal normal normal 20px/24px Proxima Nova",
                  letterSpacing: "0.4px",
                  color: "#4A4A4A"
                }}>
                  {data.allStrapiMembership.nodes[0].addressInfo}
                </p>
              </div>
            </div>
            {/* <Button
              id="pay-individual"
              label="Sign Up"
              type="submit"
              btnStyle="primary"
              btnSize="large"
              onClick={onMembershipClick}
            />                   */}
            {/* <DownloadStaticFileButton
              className={styles.action}
              label="Download Form"
              btnSize="large"
              fullWidth
              filename={data.allStrapiMembership.nodes[0].formPdf}
            /> */}
          </div>
        </div>
      </div>
    </WebsiteLayout>
  )
}

export default MembershipPage
