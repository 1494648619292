import styles from "./index.module.scss"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import Button from "../button/button"
import TextField from "../form/text-field/text-field"
import TextArea from "../form/text-area/text-area"
import SelectBox from "../form/select-box/select-box"
import membershipService from "../../../services/membership"
import RadioButtonList from "../form/radio-button-list/radio-button-list"
import { Select } from "@mui/material"
import { type } from "os"
import { duration } from "moment"

const MemberShipForm = ({ membershipTypes }) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [membershipType, setMembershipType] = useState()
  const [disable, setDisable] = useState(true);
  const [showText, setShowText] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [total, setTotal] = useState(false);
  const [selectionValue, setSelectionValue] = useState('');
  const [selectedDonation, setSelectedDonation] = useState('');



  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {

      const selectedOption = individualDataListOptions.find(option => option.value === values.amount);

      const billing_address = {
        address1: values.address1,
        address2: values.address2 ? values.address2 : '',
        city: values.city,
        province: values.province ? values.province : '',
        state: values.state ? values.state : '',
        country: membershipType.value,
        postalCode: values.postalCode ? values.postalCode : '',
        zipCode: values.zipCode ? values.zipCode : ''
      }
      const membershipTypeUpdatedObj = {
        billing_address: billing_address,
        amount: membershipTotal,
        donationAmount: selectedDonation,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        telephone: values.phone,
        type: selectedOption ? selectedOption.type : '',
        duration: selectedOption ? selectedOption.duration : ''
      }

      console.log("updatedObj", membershipTypeUpdatedObj);

      membershipService.sendPayment(membershipTypeUpdatedObj).then(
        result => {
          console.log('Payment sent')
        },
        error => {
          console.log('Error')
        }
      )
    },

    validate: (values, instance) => {
      setSubmitAttempted(false)
    },
  })




  const individualDataList = [{
    value: 25.00,
    label: "1 year individual subscription - $25.00",
    type: "individual",
    duration: 1
  },
  {
    value: 50.00,
    label: "2 year individual subscription - $50.00",
    type: "individual",
    duration: 2
  },
  {
    value: 125.00,
    label: "5 year individual subscription - $125.00",
    type: "individual",
    duration: 5
  },
  {
    value: 250.00,
    label: "10 year individual subscription - $250.00",
    type: "individual",
    duration: 10
  }
  ]

  const getIndividualDataList = () => {
    return individualDataList
  }
  const individualDataListOptions = getIndividualDataList();


  const selectionDataList = [{
    value: "CA",
    label: "Canada",
  },
  {
    value: "US",
    label: "United States",
  }
  ]

  const donationDataList = [{
    value: 25.00,
    label: " $25",
  },
  {
    value: 50.00,
    label: "$50",
  },
  {
    value: 100.00,
    label: "$100",
  },
  {
    value: 250.00,
    label: "$250",
  }
  ]

  const getSelectionDataList = () => {
    return selectionDataList
  }
  const selectionDataListOptions = getSelectionDataList();

  const handleChangeMembership = (value) => {
    if (value.value !== "CA") {
      setToggle(true)
      setMembershipType(value)
    } else {
      setToggle(false)
      setMembershipType(value)
    }
  }

  const handleOnChange = (value) => {
    setShowText(value)
    setSelectionValue(value)
    setTotal(Number(value) + Number(selectedDonation));
    // setDisable(value === '');
  }

  const handleDonationAmountChange = (event) => {
    setSelectedDonation(event.target.value);
    setTotal(Number(event.target.value) + Number(selectionValue));
  };


  useEffect(() => {
    if (individualDataListOptions.length > 0) {
      setSelectionValue(individualDataListOptions[0].value);
    }
  }, [])

  // Step 1 & 2: Convert to numbers if they are strings
  const numericSelectionValue = Number(selectionValue);
  const numericSelectedDonation = Number(selectedDonation);

  // Step 3: Sum the values
  const membershipTotal = numericSelectionValue + numericSelectedDonation;


  const handleChange = (event) => {
    setDisable(event.target.value === '');
  }

  const { Form, meta } = formInstance

  return (
    <div>
      <Form>
        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
          Contact Info
        </div>
        <br />
        <div>
          <div style={{ display: "flex" }}>
            <div >
              <TextField
                field="firstName"
                placeholder="First Name*"
                required
                // onChange={handleChange}
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
            <div style={{ marginLeft: "7px" }}>
              <TextField
                field="lastName"
                placeholder="Last Name*"
                // onChange={handleChange}
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <TextField
                field="email"
                placeholder="Email*"
                // onChange={handleChange}
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
            <div style={{ marginLeft: "7px" }}>
              <TextField
                field="phone"
                placeholder="Telephone"
                required
              />
            </div>
          </div>
        </div>

        <div style={{ margin: "5px 0" }}>
          <hr style={{ border: "0", height: "1px", backgroundColor: "#ccc" }} />
        </div>

        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
          Address
        </div>
        <br />
        <div>
          <TextField
            field="address1"
            placeholder="Street Address"
            required
            // onChange={handleChange}
            validate={value => {
              return !value ? "This is required!" : false
            }}
            mandatory
          />
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <div >
              <TextField
                field="address2"
                placeholder="Apt, suite, etc"
                // onChange={handleChange}
                mandatory
              />
            </div>
            <div style={{ marginLeft: "7px" }}>
              <TextField
                field="city"
                placeholder="City"
                // onChange={handleChange}
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <SelectBox
              field="types"
              options={selectionDataListOptions}
              value={membershipType}
              onChange={handleChangeMembership}
              defaultValue={'Type'}
              placeholder="Country"
            />
            {!toggle && <div style={{ marginLeft: "7px" }}>
              <TextField
                field="province"
                placeholder="Province"
                required
              />
            </div>}
            {toggle && <div style={{ marginLeft: "7px" }}>
              <TextField
                field="state"
                placeholder="State"
                required
              />
            </div>}
          </div>
          {!toggle && <div>
            <TextField
              field="postalCode"
              placeholder="Postal Code"
              required
            />
          </div>}
          {toggle && <div>
            <TextField
              field="zipCode"
              placeholder="Zip Code"
              required
            />
          </div>}
        </div>
        <div style={{ margin: "20px 0" }}>
          <hr style={{ border: "0", height: "1px", backgroundColor: "#ccc" }} />
        </div>

        <div style={{ fontSize: "15px", fontWeight: "bold" }}>Membership Options</div>
        <p style={{ fontSize: "12px" }}>select membership length</p>

        <div>
          <RadioButtonList
            field="amount"
            options={individualDataListOptions}
            defaultValue={individualDataListOptions[0].value}
            fnSetValue={handleOnChange}
          // onClick={showIcon}
          />
        </div>

        <div style={{ margin: "20px 0" }}>
          <hr style={{ border: "0", height: "1px", backgroundColor: "#ccc" }} />
        </div>

        <div style={{
          height: '140px',
          background: '#f1f2f3 0% no-repeat padding-box',
          borderRadius: '8px',
        }}>
          <br />
          <div style={{ fontSize: "15px", fontWeight: "bold", marginLeft: "10px" }}>
            Support Forest History, It's Worth Preserving!
          </div>
          <p style={{ fontSize: "12px", marginLeft: "10px" }}>Your donation helps us uncover, record, and share stories from the past.</p>
          <br />

          <div style={{ display: 'flex', gap: '10px' }}>
            {donationDataList.map((donation) => (
              <button key={donation.value}
                style={{
                  width: '47px',
                  marginLeft: "10px",
                  height: '35px',
                  textAlign: 'left',
                  font: 'normal normal normal 15px/20px Proxima Nova',
                  color: 'black',
                  border: selectedDonation === donation.value ? '1px solid #397567' : '1px solid white',
                  borderRadius: '5px',
                  backgroundColor: selectedDonation === donation.value ? '#d0e6df' : 'white',
                }} onClick={() => {
                  console.log(`Donation: $${donation.value}`);
                  setSelectedDonation(donation.value); // Step 3
                }}
              >
                {donation.label}
              </button>
            ))}
            <div style={{ width: '120px', height: '25px' }}>
              <TextField
                field="donationAmount"
                placeholder="$ Other"
                // value={donationAmount === selectedDonation ? '' : donationAmount} // Clear if selectedDonation is not custom
                onChange={handleDonationAmountChange}
              />
            </div>
          </div>
        </div>


        <div style={{ margin: "20px 0" }}>
          <hr style={{ border: "0", height: "1px", backgroundColor: "#ccc" }} />
        </div>

        <div>
          <div style={{ fontSize: "15px", marginTop: "5px", display: "flex" }}> Membership total: <div style={{ marginLeft: "15px" }}>${selectionValue}</div> </div>
          <p style={{ fontSize: "15px", marginTop: "0px", display: "flex" }}>Donation:<div style={{ marginLeft: "68px" }}> {selectedDonation !== null && selectedDonation !== '' ? `$${selectedDonation}` : 'No amount selected'}</div></p>
        </div>

        <div style={{ margin: "5px 0" }}>
          <hr style={{ border: "0", height: "1px", backgroundColor: "#ccc" }} />
        </div>

        <div style={{ fontSize: "15px", fontWeight: "bold", display: "flex" }}>Total Payment:<span style={{ color: "black", fontWeight: "bold", marginLeft: "27px", fontSize: "17px" }}>$</span>
          <div style={{ color: "black", fontWeight: "bold", fontSize: "17px" }}>{membershipTotal}</div>
        </div>

        <br />
        <br />
        <div>
          <span className="done" >
            <Button
              id="checkout"
              label="Proceed to Checkout"
              type="submit"
              btnStyle="primary"
              btnSize="medium"
            // disableStatus={disable}
            />
          </span>
        </div>
      </Form>
    </div>
  )
}
export default MemberShipForm


















{/* 
        <div className={styles.select}>


          <SelectBox
            field="types"
            options={membershipTypes}
            value={membershipType}
            onChange={handleChangeMembership}
            defaultValue={'Type'}
          />


        </div> */}


{/* {membershipType &&
          <div>
            <div className={styles.info}>
              <div className={styles.field}>
                {/* <div>Amount:</div>
                <div>Tax:</div> 
                <div>Total Amount:</div>
              </div>
              <div className={styles.values}> 
                {/* <div> CAD ${(membershipType.price).toFixed(2)}</div> */}
{/* <div> CAD ${(membershipType.tax).toFixed(2)}</div> */ }
{/* <div> CAD ${(membershipType.price).toFixed(2)}</div>
              </div>
            </div>

            <br />
            <div className="btns">
              <span className="done">
                <Button
                  id="create-group"
                  label="Proceed to Checkout"
                  type="submit"
                  btnStyle="primary"
                  btnSize="small"
                />
              </span>
            </div>
          </div>
        } */}